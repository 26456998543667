
import ViewAllButton from "@/components/base/button/ViewAllButton"

export default {
    name: "ShowMoreCard",
    components: { ViewAllButton },
    props: {
        number: {
            required: true,
            type: Number,
        },
        text: {
            required: true,
            type: String,
        },
        to: {
            required: true,
            type: [Object, String],
        },
    },
}
